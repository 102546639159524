.gpt3__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.gpt3__features-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.gpt3__features-heading {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    margin: 1%;
    margin-left: 2%;
    
    font-family: var(--font-family);
}

.gpt3__features-heading p {
    color: var(--color-subtext);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);

    margin-top: 2rem;
}

.gpt3__features-container {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.schoolBoardSection {
    position: absolute;
    width: 40.1%;
    height: 9%;
    background-color: rgba(255, 255, 255, 1);
    border: solid .15em;
    border-radius: 2em;
    border-color: rgba(191, 191, 191, 1);
    align-items:first baseline;
    display: flex;
    flex-direction: column;
    

    left: 19.5%;
    top: 14.5%;

    
}

.surveyTypePicture {
    position: absolute;
    width: 4.75%;
    height: 9.1%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50%;
    border: solid .15em;
    border-color: rgba(191, 191, 191, 1);
    left: 61%;
    top: 14%;
}

.iconCenter {
    margin: 2.5%
}

.reportTypeSection {
    position: absolute;
    width: 19.5%;
    height: 35.5%;
    background-color: rgba(255, 255, 255, 1);
    border: solid .15em;
    border-radius: 2em;
    border-color: rgba(191, 191, 191, 1);
    left: 19.5%;
    top: 25%;
}

.advancedOptionsSection {
    position: absolute;
    width: 19.5%;
    height: 8%;
    background-color: rgba(255, 255, 255, 1);
    border: solid .15em;
    border-radius: 2em;
    border-color: rgba(191, 191, 191, 1);
    left: 19.5%;
    top: 62%;
}

.dateRangeSection {
    position: absolute;
    width: 19.5%;
    height: 11%;
    background-color: rgba(255, 255, 255, 1);
    border: solid .15em;
    border-radius: 2em;
    border-color: rgba(191, 191, 191, 1);
    top: 25%;
    left: 40%;
}

.schoolSizeSection {
    position: absolute;
    width: 19.5%;
    height: 15%;
    background-color: rgba(255, 255, 255, 1);
    border: solid .15em;
    border-radius: 2em;
    border-color: rgba(191, 191, 191, 1);
    top: 37.5%;
    left: 40%;
}

.spreadsheetFormatSection {
    position: absolute;
    width: 19.5%;
    height: 40%;
    background-color: rgba(255, 255, 255, 1);
    border: solid .15em;
    border-radius: 2em;
    border-color: rgba(191, 191, 191, 1);
    top: 25%;
    left: 60.5%;
}

.generateReportSection {
    position: absolute;
    width: 19.5%;
    height: 10%;
    background-color: rgba(255, 255, 255, 1);
    border: solid .15em;
    border-radius: 2em;
    border-color: rgba(191, 191, 191, 1);
    top: 80%;
    left: 40%;
}

@media screen and (max-width: 1400px){
    
    .surveyTypePicture {
        position: absolute;
        width: 80px;
        height: 80px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 50%;
        border: solid .15em;
        border-color: rgba(191, 191, 191, 1);
        left: 61%;
        top: 14%;
    }
}

@media screen and (max-width: 990px) {
    .gpt3__features {
        flex-direction: column;
    }

    .gpt3__features-heading {
        margin: 0 0 2rem 0;
    }

    .surveyTypePicture {
        position: absolute;
        width: 70px;
        height: 70px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 50%;
        border: solid .15em;
        border-color: rgba(191, 191, 191, 1);
        left: 61%;
        top: 14%;
    }
}


@media screen and (max-width: 550px) {
    .gpt3__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }

    .surveyTypePicture {
        position: absolute;
        width: 60px;
        height: 60px;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 50%;
        border: solid .15em;
        border-color: rgba(191, 191, 191, 1);
        left: 61%;
        top: 14%;
    }
}