* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    /*background: var(--color-bg)*/
    background: linear-gradient(to right, rgb(93, 174, 209), rgb(255, 255, 255) 200%);
    background-size: cover;
    overflow-x: hidden;
    overflow-y: hidden;
}

.shape {
    position: absolute;
    width: 395px;
    height: 400px;
    background-color: rgba(4, 35, 48, 0);
    border-radius: 50%;
    border-style:solid;
    border-color: rgba(4, 35, 48, .4);
    left: -197.5px;
    top: -200px;
}

.shape2 {
    position: absolute;
    width: 800px;
    height: 795px;
    background-color: rgba(4, 35, 48, 0);
    border-radius: 50%;
    border-style:solid;
    border-color: rgba(4, 35, 48, .4);
    left: -400px;
    top: -397.5px;
}

.shape3 {
    position: absolute;
    width: 923px;
    height: 934px;
    background-color: rgba(4, 35, 48, 0);
    border-radius: 50%;
    border-style:solid;
    border-color: rgba(4, 35, 48, .4);
    left: -361.5px;
    top: -367px;
}

.shape4 {
    position: absolute;
    width: 397px;
    height: 541px;
    background-color: rgba(209, 227, 235, 1);
    border-radius: 50%;
    left: -200px;
    bottom: -200px;
}

.shape5 {
    position: absolute;
    width: 1966px;
    height: 1317px;
    background-color: rgba(4, 35, 48, 0);
    border-radius: 50%;
    border-style:solid;
    border-color: rgba(4, 35, 48, .4);
    right: -500px;
    bottom: -1200px;
    max-width: 100%;
}

.shape6 {
    position: absolute;
    width: 397px;
    height: 741px;
    background-color: rgba(209, 227, 235, 1);
    border-radius: 50%;
    right: -300px;
    bottom: 600px;
}

.shape7 {
    position: absolute;
    width: 500px;
    height: 741px;
    background-color: rgba(4, 35, 48, 0);
    border-radius: 50%;
    border-style:solid;
    border-color: rgba(209, 227, 235, 1);
    right: -300px;
    bottom: -300px;
}


a {
    color: unset;
    text-decoration: none;
}

/*.gradient__bg {
    /* global 92%+ browsers support 
    background: radial-gradient(circle at 3% 25%, rgb(93, 174, 209) 0%, rgb(255, 255, 255) 200%);
}*/

.gradient__text {
    background:var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }
    .section__margin {
        margin: 4rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }
    .section__margin {
        margin: 4rem 2rem;
    }
}

